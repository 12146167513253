.pricing {
    display: flex;
    justify-content: space-around;
  }
  
  .pricing-card {
    border: 1px solid #ccc;
    padding: 20px;
    width: 300px;
  }
  
  .price-container {
    display: flex;
    align-items: baseline;
  }
  
  .price {
    font-size: 4em; /* Increase the size of the price */
    font-weight: bold; /* Increase the weight of the price */
    margin: 20px 0; /* Add margin above and below the price */
  }
  
  .one-time {
    font-size: 1em; /* Decrease the size of "one time" */
    margin-left: 5px;
  }
    
  .features {
    list-style: none;
    padding: 0;
  }
  
  .features li {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .checkmark-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
    color: white;
    text-align: center;
    margin-right: 10px;
  }
  
  .buy-now {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px; /* This gives the button rounded corners. Adjust as needed. */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* This gives the button a slight drop shadow. Adjust as needed. */
    transition: box-shadow 0.3s ease; /* This creates a transition effect when the shadow changes. */
    font-weight: 400;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    box-sizing: border-box;
}
    
  .card.course-card {
    width: 350px;
    height: auto;
    max-height: 800px;
    margin: 20px;
  }
  
  .card-content {
    width: 100%;
    height: 100%;
    max-height: 600px;
    overflow: auto;
  }

  .card.course-card .card-subtitle {
    font-weight: bold; /* Or use a specific weight like 700 */
    font-size: 20px;
    margin-top: 20px;
  }
  
  
  .features {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .feature-item {
    display: flex;
    align-items: flex-start; /* Align items to the start */
    margin: 10px;
  }
  
  .feature-item span {
    margin-left: 10px;
    flex: 1; /* Allow the text to take up the remaining space */
  }
    
  .feature-text {
    width: 90%; /* adjust this value as needed */
    margin-left: 10px; /* adjust this value as needed */
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .faq-container {
    width: 100%;
    padding: 0 10px; /* slight padding on the sides to prevent content sticking to edges */
  }
  
  .pricing {
    flex-direction: column;
    align-items: center; /* center the cards */
  }
  
  .pricing-card {
    width: 100%; /* Make the card take full width */
    max-width: 350px; /* set a max width */
    margin: 10px 0; /* some vertical space between the cards */
  }
  
  .price {
    font-size: 2.5em;
  }
  
  .one-time {
    font-size: 1em;
  }
  
  .card.course-card .card-subtitle {
    font-size: 18px; /* reduce the font size a bit for mobile */
  }
  
  .features {
    padding-left: 5px;
  }
  
  .feature-item span {
    margin-left: 5px;
  }
  
  .buy-now {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
