.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F2EDDC; /* Updated background color */
  padding: 1rem;
}

.nav {
  margin-right: 1rem;
}

.nav-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-left: 1rem;
}

.nav-item a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.nav-item a:hover {
  color: #103878;
}

.logo img {
  max-height: 50px;
  object-fit: contain;
}

.nav-link-active a {
  color: #103878 !important;
  font-weight: 700;
}