.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

.contact-page form {
    width: 100%;
    max-width: 500px;
}

.subtitle2 {
    font-size: 3.5em; /* Adjust as needed. */
    font-weight: 600;
    padding-top: 20px; /* Adjust as needed. */
    color: #222;
    max-width: 800px;
  }

  .subheading2 {
    font-weight: 500;
    color: #888888;
    font-size: 24px;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    line-height: 1.5;  /* Increase line spacing */
    text-align: center;
    max-width: 800px;  /* Set a maximum width for the subheading */
    width: 80%;  /* Make the subheading take up 80% of the width of its container */
}
.subheading3 {
    font-weight: 500;
    color: #888888;
    font-size: 24px;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    line-height: 1.5;  /* Increase line spacing */
    text-align: center;
    max-width: 800px;  /* Set a maximum width for the subheading */
    width: 80%;  /* Make the subheading take up 80% of the width of its container */
}

.send-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;  /* Make the button the same width as the input fields */
}

@media (max-width: 767px) {
    .subtitle2 {
        font-size: 3em;
      }
      .subheading3 {
        font-size: 16px;
      }
    }    
