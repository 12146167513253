/* Reset margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.subtitle3 {
  padding-top: 20px;
  font-size: 3.5em;
  font-weight: 600;
  color: #222;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
}

.products-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.product {
  width: 50%;
}

.product h2 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.product p {
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 400;
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .faq-container {
    width: 100%; /* Make the container take full width */
    max-width: 100%; /* Ensure it does not exceed 100% width */
    margin: 0; /* Remove margin */
  }

  .product {
    width: 100%;
  }

  .product p {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 18px;
  }

  .subtitle3 {
    font-size: 3em;
  }
  
  .product h2 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
