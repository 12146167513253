.main-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  text-align: center;
  padding: 100px;
}
  
.title {
  font-size: 5.5em;
  font-weight: 700;
  color: #222;
}
  
.subtitle5 {
  margin-top: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #888888;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 1.5;
}
  
.button-container {
  display: flex;
  gap: 20px;
  margin-top: 40px;
}
  
  
  .get-started-btn, .learn-more-btn {
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }
  
  .get-started-btn {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px; /* This gives the button rounded corners. Adjust as needed. */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* This gives the button a slight drop shadow. Adjust as needed. */
    transition: box-shadow 0.3s ease; /* This creates a transition effect when the shadow changes. */
    font-weight: 600;
    text-decoration: none;
  }
  
  .get-started-btn:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* This makes the shadow deeper when the button is hovered. Adjust as needed. */
  }
  
  .learn-more-btn {
    background-color: #f0f0f0; /* Adjust as needed. */
    color: #222;
  }
  
    
  /* Styles for screens that are 600px wide or less */
@media (max-width: 1024px) {
  .main-header {
    padding: 80px;
  }
    
  .title {
    font-size: 3em;
  }
  
  .subtitle5 {
    font-size: 24px;
  }
  
  .get-started-btn {
    padding: 8px 16px;
  }
}
  
  @media (max-width: 767px) {
    .main-header {
      padding: 20px;
    }
      
    .title {
      font-size: 3em;
    }
      
    .subtitle5 {
      font-size: 24px;
    }
  
    .button-container {
      flex-direction: column;
      align-items: center;
    }
  
    .get-started-btn {
      padding: 10px 20px;
      width: 100%;
      max-width: 200px;
      margin-bottom: 10px;
    }
  }
    
  