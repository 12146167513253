.subtitle {
    font-size: 3.5em; /* Adjust as needed. */
    font-weight: 600;
    padding-top: 50px; /* Adjust as needed. */
    color: #222;
    margin-bottom: 30px;
  }

  .subheading {
    font-weight: 500;
    color: #888888;
    font-size: 24px;
    margin-bottom: 30px;
    margin: auto;  /* Center the subheading */
    line-height: 1.5;  /* Increase line spacing */
    text-align: center;
    width: 500px;
  }

.search-page {
    text-align: center;
    padding-top: 50px;
}

.search-page h1, .search-page h2 {
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.search-bar input {
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.search-bar button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


