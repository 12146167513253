body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F2EDDC; /* Updated background color */
  padding: 1rem;
}

.nav {
  margin-right: 1rem;
}

.nav-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-left: 1rem;
}

.nav-item a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.nav-item a:hover {
  color: #103878;
}

.logo img {
  max-height: 50px;
  object-fit: contain;
}

.nav-link-active a {
  color: #103878 !important;
  font-weight: 700;
}
.explore-courses h3 {
  margin-top: 32px;
  margin-bottom: 16px;
}

.explore-courses table {
  margin-top: 32px;
}

.menu-icon {
  display: none;
  font-size: 28px;
  cursor: pointer;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 1000;
}

.nav-link, .login-signup-btn {
  margin: 10px;
  /* ... other styles ... */
}



.header {
  position: -webkit-sticky;
  position: sticky;
    top: 0;
    z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
}
  
  .logo {
    height: 50px;
    font-weight: 700;
  font-style: normal;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  color: #111111;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 2;
  text-align: center;
  text-decoration: none;

  }
  
  .nav {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    cursor: pointer;
  }
  
  .nav-link {
    color: black;
    text-decoration: none;
    font-weight: 500;
  }
  
  .login-signup-btn {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px; /* This gives the button rounded corners. Adjust as needed. */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* This gives the button a slight drop shadow. Adjust as needed. */
    transition: box-shadow 0.3s ease; /* This creates a transition effect when the shadow changes. */
    font-weight: 600;
    text-decoration: none;
  }
  
  .login-signup-btn:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* This makes the shadow deeper when the button is hovered. Adjust as needed. */
    background-color: #2355AD;
  }
  
  /* Styles for screens that are 600px wide or less */
  @media (max-width: 767px) {
    .nav {
      display: none; /* Hide navigation links on small screens */
    }
    .header {
      justify-content: center; /* Center the header contents */
    }
    
    .logo {
      text-align: center; /* Center align the text of the logo */
    }    
}

.main-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  text-align: center;
  padding: 100px;
}
  
.title {
  font-size: 5.5em;
  font-weight: 700;
  color: #222;
}
  
.subtitle5 {
  margin-top: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #888888;
  font-size: 32px;
  letter-spacing: -0.5px;
  line-height: 1.5;
}
  
.button-container {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 40px;
}
  
  
  .get-started-btn, .learn-more-btn {
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }
  
  .get-started-btn {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px; /* This gives the button rounded corners. Adjust as needed. */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* This gives the button a slight drop shadow. Adjust as needed. */
    transition: box-shadow 0.3s ease; /* This creates a transition effect when the shadow changes. */
    font-weight: 600;
    text-decoration: none;
  }
  
  .get-started-btn:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* This makes the shadow deeper when the button is hovered. Adjust as needed. */
  }
  
  .learn-more-btn {
    background-color: #f0f0f0; /* Adjust as needed. */
    color: #222;
  }
  
    
  /* Styles for screens that are 600px wide or less */
@media (max-width: 1024px) {
  .main-header {
    padding: 80px;
  }
    
  .title {
    font-size: 3em;
  }
  
  .subtitle5 {
    font-size: 24px;
  }
  
  .get-started-btn {
    padding: 8px 16px;
  }
}
  
  @media (max-width: 767px) {
    .main-header {
      padding: 20px;
    }
      
    .title {
      font-size: 3em;
    }
      
    .subtitle5 {
      font-size: 24px;
    }
  
    .button-container {
      flex-direction: column;
      align-items: center;
    }
  
    .get-started-btn {
      padding: 10px 20px;
      width: 100%;
      max-width: 200px;
      margin-bottom: 10px;
    }
  }
    
  
.lottie-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
  
.lottie-content {
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
  width: 55em;
}
  
.lottie-player {
  flex-shrink: 0;
  width: 35%;
}
  
.lottie-text {
  margin-left: 50px;
  text-align: left;
  max-width: 400px;  
}
  
    
.lottie-text p {
  font-size: 20px;
  font-weight: 400;
}

.upper {
  display: none;
}

.lower {
  display: contents;
}
  
@media (max-width: 767px) {
  .lottie-content {
    flex-direction: column;
    align-items: center; /* Added to center the content */
    margin: 20px 10px; /* Reduced horizontal margin */
  }
  
  .lottie-player {
    width: 80%; /* Increased width for smaller screens */
  }
  
  .lottie-text {
    margin-left: 0;
    text-align: center;
    margin-top: 20px; /* Added space between the animation and the text */
  }
  
  .lottie-text p {
    font-size: 18px; /* Reduced font size for smaller screens */
  }
  .upper {
    display: contents;
  }
  .lower {
    display: none;
  }
}
    
/* Reset margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.subtitle3 {
  padding-top: 20px;
  font-size: 3.5em;
  font-weight: 600;
  color: #222;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
}

.products-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.product {
  width: 50%;
}

.product h2 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.product p {
  padding-left: 50px;
  padding-right: 50px;
  font-size: 20px;
  font-weight: 400;
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .faq-container {
    width: 100%; /* Make the container take full width */
    max-width: 100%; /* Ensure it does not exceed 100% width */
    margin: 0; /* Remove margin */
  }

  .product {
    width: 100%;
  }

  .product p {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 18px;
  }

  .subtitle3 {
    font-size: 3em;
  }
  
  .product h2 {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.pricing {
    display: flex;
    justify-content: space-around;
  }
  
  .pricing-card {
    border: 1px solid #ccc;
    padding: 20px;
    width: 300px;
  }
  
  .price-container {
    display: flex;
    align-items: baseline;
  }
  
  .price {
    font-size: 4em; /* Increase the size of the price */
    font-weight: bold; /* Increase the weight of the price */
    margin: 20px 0; /* Add margin above and below the price */
  }
  
  .one-time {
    font-size: 1em; /* Decrease the size of "one time" */
    margin-left: 5px;
  }
    
  .features {
    list-style: none;
    padding: 0;
  }
  
  .features li {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  
  .checkmark-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: black;
    color: white;
    text-align: center;
    margin-right: 10px;
  }
  
  .buy-now {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px; /* This gives the button rounded corners. Adjust as needed. */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* This gives the button a slight drop shadow. Adjust as needed. */
    transition: box-shadow 0.3s ease; /* This creates a transition effect when the shadow changes. */
    font-weight: 400;
    text-decoration: none;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
    box-sizing: border-box;
}
    
  .card.course-card {
    width: 350px;
    height: auto;
    max-height: 800px;
    margin: 20px;
  }
  
  .card-content {
    width: 100%;
    height: 100%;
    max-height: 600px;
    overflow: auto;
  }

  .card.course-card .card-subtitle {
    font-weight: bold; /* Or use a specific weight like 700 */
    font-size: 20px;
    margin-top: 20px;
  }
  
  
  .features {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .feature-item {
    display: flex;
    align-items: flex-start; /* Align items to the start */
    margin: 10px;
  }
  
  .feature-item span {
    margin-left: 10px;
    flex: 1 1; /* Allow the text to take up the remaining space */
  }
    
  .feature-text {
    width: 90%; /* adjust this value as needed */
    margin-left: 10px; /* adjust this value as needed */
}

/* Media query for screens smaller than 768px */
@media (max-width: 767px) {
  .faq-container {
    width: 100%;
    padding: 0 10px; /* slight padding on the sides to prevent content sticking to edges */
  }
  
  .pricing {
    flex-direction: column;
    align-items: center; /* center the cards */
  }
  
  .pricing-card {
    width: 100%; /* Make the card take full width */
    max-width: 350px; /* set a max width */
    margin: 10px 0; /* some vertical space between the cards */
  }
  
  .price {
    font-size: 2.5em;
  }
  
  .one-time {
    font-size: 1em;
  }
  
  .card.course-card .card-subtitle {
    font-size: 18px; /* reduce the font size a bit for mobile */
  }
  
  .features {
    padding-left: 5px;
  }
  
  .feature-item span {
    margin-left: 5px;
  }
  
  .buy-now {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
}

.contact-page form {
    width: 100%;
    max-width: 500px;
}

.subtitle2 {
    font-size: 3.5em; /* Adjust as needed. */
    font-weight: 600;
    padding-top: 20px; /* Adjust as needed. */
    color: #222;
    max-width: 800px;
  }

  .subheading2 {
    font-weight: 500;
    color: #888888;
    font-size: 24px;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    line-height: 1.5;  /* Increase line spacing */
    text-align: center;
    max-width: 800px;  /* Set a maximum width for the subheading */
    width: 80%;  /* Make the subheading take up 80% of the width of its container */
}
.subheading3 {
    font-weight: 500;
    color: #888888;
    font-size: 24px;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    line-height: 1.5;  /* Increase line spacing */
    text-align: center;
    max-width: 800px;  /* Set a maximum width for the subheading */
    width: 80%;  /* Make the subheading take up 80% of the width of its container */
}

.send-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;  /* Make the button the same width as the input fields */
}

@media (max-width: 767px) {
    .subtitle2 {
        font-size: 3em;
      }
      .subheading3 {
        font-size: 16px;
      }
    }    

.subtitle {
    font-size: 3.5em; /* Adjust as needed. */
    font-weight: 600;
    padding-top: 50px; /* Adjust as needed. */
    color: #222;
    margin-bottom: 30px;
  }

  .subheading {
    font-weight: 500;
    color: #888888;
    font-size: 24px;
    margin-bottom: 30px;
    margin: auto;  /* Center the subheading */
    line-height: 1.5;  /* Increase line spacing */
    text-align: center;
    width: 500px;
  }

.search-page {
    text-align: center;
    padding-top: 50px;
}

.search-page h1, .search-page h2 {
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

.search-bar input {
    width: 300px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.search-bar button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}



.course-card {
    width: 18rem;
    /* You can adjust the height as needed */
    min-width: 18rem;  /* Ensure the cards maintain their width */
    height: 24rem;
    border-radius: 10px;  /* Add border radius to the card */
    overflow: hidden;  /* Hide the overflowing parts of the child elements (like the image) */
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);  /* Add a stronger drop shadow to the card */
    border: none;  /* Remove the border */
}


.card-img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.mb-2 {
    font-size: 0.8em;  /* Make the font size smaller */
    background-color: #333;  /* Dark grey background */
    color: #fff;  /* White text */
    border-radius: 5px;  /* Rounded edges */
    padding: 5px 5px;  /* Add some padding around the text */
    display: inline-block;  /* To keep the background only as wide as the text */
}

.card-title {
    font-weight: 600;  /* Increase the font weight of the title */
}

.card-section {
    margin: 80px;  /* Increase the margin around the card section */
}

.card-section h1 {
    margin-bottom: 30px;
    text-align: left;
}

.card-list {
    display: flex;
    overflow-x: auto;
    grid-gap: 40px;
    gap: 40px;
    padding: 10px;  /* Add padding around the cards */
}

.signup-container2 {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: left;
}

.bold-label {
    font-weight: bold;
}

.custom-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.center-text {
    text-align: center;
    margin: 2rem 0;
}

.mb-3 {
    margin-bottom: 2rem !important; /* adjust as needed */
  }

  .disclaimer {
    font-size: 0.9em;
    color: #666;
    margin-top: 20px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(255, 241, 220);
    border-radius: 10px;
}

.connect-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    
}
  

  
.signup-page {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.course-page {
    text-align: center;
  }
  
  .course-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-gap: 30px;
    gap: 30px;
    margin: 1em;
    margin-left: 5em; /* Add margin around the whole block */
    margin-right: 5em;
    
  }
    
  .course-info {
    flex: 1 1;
    text-align: left; /* Align text to the left */
    max-width: 500px;
  }
    
  .course-banner {
    flex: 0.8 1; /* Adjust the size of the image */
    height: 300px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Scale the image to cover the element */
    box-sizing: border-box; /* Include the padding in the element's total width and height */
    border-radius: 10px; /* Add rounded corners */
    max-width: 500px;
  }
      
  .course-title {
    font-size: 3.5em; /* Adjust as needed. */
    font-weight: 600;
    padding-top: 20px; /* Adjust as needed. */
    color: #222;
  }
  
  .course-summary {
    margin: 1em 0;
    font-weight: 500;
    color: #888888;
    font-size: 24px;
    line-height: 1.5;  /* Increase line spacing */
  }
  
  .course-tags {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1em;
    gap: 1em;
    margin: 1em 0;
  }
  
  .tag {
    font-size: 0.8em;  /* Make the font size smaller */
    background-color: #333;  /* Dark grey background */
    color: #fff;  /* White text */
    border-radius: 5px;  /* Rounded edges */
    padding: 5px 5px;  /* Add some padding around the text */
    display: inline-block;  /* To keep the background only as wide as the text */
  }
  
.grid {
    display: flex;
    border: 1px solid #ccc;
    user-select: none;       /* Standard syntax */
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none;    /* Firefox 2+ */
    -ms-user-select: none;     /* IE 10+ */
}

.column {
    flex: 1 1;
    border-right: 1px solid #ccc;
}

.column:last-child {
    border-right: none;
}

.time-slot {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.time-slot:last-child {
    border-bottom: none;
}

.time-slot.selected {
    background-color: #4CAF50;
    color: white;
}

.navigation {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

button {
    background-color: #000; /* Blue color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 5px;
    cursor: pointer;
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

button:disabled {
    background-color: #a3a3a3; /* Gray out disabled buttons */
    cursor: not-allowed;
}

.card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

/* Card styles */
.card {
    width: 100%;
    max-width: 400px; /* Adjust width as needed */
    padding: 20px;
    margin: auto;
    border: 1px solid #ccc; /* Add a border */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Dropshadow */
    background-color: white;
}

.date-navigation {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Maximize space between items */
    margin-bottom: 10px;
}

.calendar-header {
    font-size: 1.2em;
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}
