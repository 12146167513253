.course-page {
    text-align: center;
  }
  
  .course-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 30px;
    margin: 1em;
    margin-left: 5em; /* Add margin around the whole block */
    margin-right: 5em;
    
  }
    
  .course-info {
    flex: 1;
    text-align: left; /* Align text to the left */
    max-width: 500px;
  }
    
  .course-banner {
    flex: 0.8; /* Adjust the size of the image */
    height: 300px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Scale the image to cover the element */
    box-sizing: border-box; /* Include the padding in the element's total width and height */
    border-radius: 10px; /* Add rounded corners */
    max-width: 500px;
  }
      
  .course-title {
    font-size: 3.5em; /* Adjust as needed. */
    font-weight: 600;
    padding-top: 20px; /* Adjust as needed. */
    color: #222;
  }
  
  .course-summary {
    margin: 1em 0;
    font-weight: 500;
    color: #888888;
    font-size: 24px;
    line-height: 1.5;  /* Increase line spacing */
  }
  
  .course-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin: 1em 0;
  }
  
  .tag {
    font-size: 0.8em;  /* Make the font size smaller */
    background-color: #333;  /* Dark grey background */
    color: #fff;  /* White text */
    border-radius: 5px;  /* Rounded edges */
    padding: 5px 5px;  /* Add some padding around the text */
    display: inline-block;  /* To keep the background only as wide as the text */
  }
  