.signup-container2 {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: left;
}

.bold-label {
    font-weight: bold;
}

.custom-button {
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.center-text {
    text-align: center;
    margin: 2rem 0;
}

.mb-3 {
    margin-bottom: 2rem !important; /* adjust as needed */
  }

  .disclaimer {
    font-size: 0.9em;
    color: #666;
    margin-top: 20px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(255, 241, 220);
    border-radius: 10px;
}

.connect-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    
}
  

  