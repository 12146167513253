.card-section {
    margin: 80px;  /* Increase the margin around the card section */
}

.card-section h1 {
    margin-bottom: 30px;
    text-align: left;
}

.card-list {
    display: flex;
    overflow-x: auto;
    gap: 40px;
    padding: 10px;  /* Add padding around the cards */
}
