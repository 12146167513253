.lottie-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
  
.lottie-content {
  display: flex;
  margin: 20px;
  align-items: center;
  justify-content: center;
  width: 55em;
}
  
.lottie-player {
  flex-shrink: 0;
  width: 35%;
}
  
.lottie-text {
  margin-left: 50px;
  text-align: left;
  max-width: 400px;  
}
  
    
.lottie-text p {
  font-size: 20px;
  font-weight: 400;
}

.upper {
  display: none;
}

.lower {
  display: contents;
}
  
@media (max-width: 767px) {
  .lottie-content {
    flex-direction: column;
    align-items: center; /* Added to center the content */
    margin: 20px 10px; /* Reduced horizontal margin */
  }
  
  .lottie-player {
    width: 80%; /* Increased width for smaller screens */
  }
  
  .lottie-text {
    margin-left: 0;
    text-align: center;
    margin-top: 20px; /* Added space between the animation and the text */
  }
  
  .lottie-text p {
    font-size: 18px; /* Reduced font size for smaller screens */
  }
  .upper {
    display: contents;
  }
  .lower {
    display: none;
  }
}
    