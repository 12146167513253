.grid {
    display: flex;
    border: 1px solid #ccc;
    user-select: none;       /* Standard syntax */
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none;    /* Firefox 2+ */
    -ms-user-select: none;     /* IE 10+ */
}

.column {
    flex: 1;
    border-right: 1px solid #ccc;
}

.column:last-child {
    border-right: none;
}

.time-slot {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.time-slot:last-child {
    border-bottom: none;
}

.time-slot.selected {
    background-color: #4CAF50;
    color: white;
}

.navigation {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

button {
    background-color: #000; /* Blue color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 5px;
    cursor: pointer;
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

button:hover {
    background-color: #0056b3; /* Darker blue on hover */
}

button:disabled {
    background-color: #a3a3a3; /* Gray out disabled buttons */
    cursor: not-allowed;
}

.card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

/* Card styles */
.card {
    width: 100%;
    max-width: 400px; /* Adjust width as needed */
    padding: 20px;
    margin: auto;
    border: 1px solid #ccc; /* Add a border */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Dropshadow */
    background-color: white;
}

.date-navigation {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Maximize space between items */
    margin-bottom: 10px;
}

.calendar-header {
    font-size: 1.2em;
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}