.course-card {
    width: 18rem;
    /* You can adjust the height as needed */
    min-width: 18rem;  /* Ensure the cards maintain their width */
    height: 24rem;
    border-radius: 10px;  /* Add border radius to the card */
    overflow: hidden;  /* Hide the overflowing parts of the child elements (like the image) */
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);  /* Add a stronger drop shadow to the card */
    border: none;  /* Remove the border */
}


.card-img {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.mb-2 {
    font-size: 0.8em;  /* Make the font size smaller */
    background-color: #333;  /* Dark grey background */
    color: #fff;  /* White text */
    border-radius: 5px;  /* Rounded edges */
    padding: 5px 5px;  /* Add some padding around the text */
    display: inline-block;  /* To keep the background only as wide as the text */
}

.card-title {
    font-weight: 600;  /* Increase the font weight of the title */
}
