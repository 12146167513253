.menu-icon {
  display: none;
  font-size: 28px;
  cursor: pointer;
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  z-index: 1000;
}

.nav-link, .login-signup-btn {
  margin: 10px;
  /* ... other styles ... */
}



.header {
  position: sticky;
    top: 0;
    z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 10px 20px;
}
  
  .logo {
    height: 50px;
    font-weight: 700;
  font-style: normal;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  color: #111111;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 2;
  text-align: center;
  text-decoration: none;

  }
  
  .nav {
    display: flex;
    gap: 20px;
    cursor: pointer;
  }
  
  .nav-link {
    color: black;
    text-decoration: none;
    font-weight: 500;
  }
  
  .login-signup-btn {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 8px; /* This gives the button rounded corners. Adjust as needed. */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* This gives the button a slight drop shadow. Adjust as needed. */
    transition: box-shadow 0.3s ease; /* This creates a transition effect when the shadow changes. */
    font-weight: 600;
    text-decoration: none;
  }
  
  .login-signup-btn:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* This makes the shadow deeper when the button is hovered. Adjust as needed. */
    background-color: #2355AD;
  }
  
  /* Styles for screens that are 600px wide or less */
  @media (max-width: 767px) {
    .nav {
      display: none; /* Hide navigation links on small screens */
    }
    .header {
      justify-content: center; /* Center the header contents */
    }
    
    .logo {
      text-align: center; /* Center align the text of the logo */
    }    
}
